import Login from 'src/pages/Login'
import PlaylistList from 'src/pages/Playlist'
import Metaverse from 'src/pages/Metaverse'
import EditMetaverse from 'src/pages/Metaverse/EditMetaverse'
import Maze from 'src/pages/Maze'
import EditMaze from 'src/pages/Maze/EditMaze'
import Game from 'src/pages/Games'
import Guys from 'src/pages/Guys'
import EditGuys from './pages/Guys/editguys'
import EditGames from 'src/pages/Games/EditGames'
import Marketplace from 'src/pages/Marketplace'
import EditMarketplace from 'src/pages/Marketplace/EditMarketplace'
import AddMarketplace from 'src/pages/Marketplace/AddMarketplace'
import MonhtlyMarketplace from 'src/pages/Marketplace/MonhtlyMarketplace'
import RedeemUsers from 'src/pages/RedeemUsers'
import MonthlyWinners from 'src/pages/Marketplace/MonthlyWinners'

import PlaylistDetail from 'src/pages/Playlist/Detail'
import TrackList from 'src/pages/Track'
import StationList from 'src/pages/Station'
import StationDetail from 'src/pages/Station/Detail'
import TagList from 'src/pages/Tag'
import TagDetail from 'src/pages/Tag/Detail'

import PlaylistImages from 'src/pages/Image/playlist'
import UsersList from 'src/pages/user'
import PlaylistReport from 'src/pages/Reports/PlaylistReport/playlistReport'
import StationReport from 'src/pages/Reports/StationsReport/stationReport'
import TracksReport from 'src/pages/Reports/TracksReport/tracksReport'
import AppUsageReport from 'src/pages/Reports/AppUsageReport/appUsageReport'
import MetaverseReport from 'src/pages/Reports/MetaverseReport/metaverseReport'

import MazeReport from 'src/pages/Reports/MazeReport/mazeReport'

import UserSearchReport from 'src/pages/Reports/UserSearchReport/userSearchReport'
import ProfileChangeReport from 'src/pages/Reports/UserProfileChangeReport/userProfileChangeReport'
import GameUsageReport from 'src/pages/Reports/GameUsageReport/gameUsageReport'
import GameDiscovery from './pages/Discovery/Games'
import PlaylistDiscovery from './pages/Discovery/Playlists'
import TrackDiscovery from './pages/Discovery/Tracks'
import Faq from 'src/pages/Faq'
import Abuse from 'src/pages/Abuse'
import MetaverseDiscovery from './pages/Discovery/Metaverse'
import GameSortingDiscovery from './pages/Discovery/Mazes'
import MazeDiscovery from './pages/Discovery/Mazes'
import Unauthorized from './pages/unauthorized/unauthorized'
// import Welcome from './pages/welcome/welcome'
import Dashboard from './pages/welcome/dashboard'
import UserDetail from './pages/Reports/AppUsageReport/UserDetail'
import Admin from './pages/Admin'

export default [
  {
    path: '/playlist',
    component: PlaylistList,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/metaverse',
    component: Metaverse,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/games',
    component: Game,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/guys',
    component: Guys,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/guys/edit/:id',
    component: EditGuys,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/marketplace',
    component: Marketplace,
    protected: true,
    requiredprivileges: ['marketplace', 'superadmin'],
  },
  {
    path: '/games/edit/:id',
    component: EditGames,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/metaverse/edit/:id/:img/:thumbImg/:title/:titleFr/:desc',
    component: EditMetaverse,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/marketplace/edit/:id',
    component: EditMarketplace,
    protected: true,
    requiredprivileges: ['marketplace', 'superadmin'],
  },
  {
    path: '/maze/edit/:id',
    component: EditMaze,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/marketplace/add',
    component: AddMarketplace,
    protected: true,
    requiredprivileges: ['marketplace', 'superadmin'],
  },
  {
    path: '/marketplace/monthly',
    component: MonhtlyMarketplace,
    protected: true,
    requiredprivileges: ['marketplace', 'superadmin'],
  },
  {
    path: '/marketplace/monthly/winners',
    component: MonthlyWinners,
    protected: true,
    requiredprivileges: ['marketplace', 'superadmin'],
  },
  {
    path: '/maze',
    component: Maze,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/faq',
    component: Faq,
    protected: true,
    requiredprivileges: ['users', 'superadmin'],
  },
  {
    path: '/abuse',
    component: Abuse,
    protected: true,
    requiredprivileges: ['users', 'superadmin'],
  },
  {
    path: '/playlist/:id',
    component: PlaylistDetail,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },

  {
    path: '/track',
    component: TrackList,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/station',
    component: StationList,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/station/:id',
    component: StationDetail,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/login',
    component: Login,
    protected: false,
    blankLayout: true,
  },
  {
    path: '/tag',
    component: TagList,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/tag/:id',
    component: TagDetail,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },

  {
    path: '/image/playlist',
    component: PlaylistImages,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/users',
    component: UsersList,
    protected: true,
    requiredprivileges: ['users', 'superadmin'],
  },
  {
    path: '/redeemUsers',
    component: RedeemUsers,
    protected: true,
    requiredprivileges: ['marketplace', 'superadmin'],
  },
  {
    path: '/reports/playlist-reports',
    component: PlaylistReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/station-reports',
    component: StationReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/tracks-reports',
    component: TracksReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/appUsage-reports',
    component: AppUsageReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/metaverse-reports',
    component: MetaverseReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/maze-reports',
    component: MazeReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/usersSearch-reports',
    component: UserSearchReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/usersSearch-reports/user',
    component: UserDetail,
  },
  {
    path: '/reports/userProfileChange-reports',
    component: ProfileChangeReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/reports/gameUsage-reports',
    component: GameUsageReport,
    protected: true,
    requiredprivileges: ['report', 'superadmin'],
  },
  {
    path: '/discovery/playlist-discovery',
    component: PlaylistDiscovery,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/discovery/games-discovery',
    component: GameDiscovery,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/discovery/tracks-discovery',
    component: TrackDiscovery,
    protected: true,
    requiredprivileges: ['music', 'superadmin'],
  },
  {
    path: '/discovery/metaverse-discovery',
    component: MetaverseDiscovery,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/discovery/maze-discovery',
    component: MazeDiscovery,
    protected: true,
    requiredprivileges: ['unity', 'superadmin'],
  },
  {
    path: '/dashboard',
    component: Dashboard,
    protected: true,
  },
  {
    path: '/access',
    component: Admin,
    protected: true,
    requiredprivileges: ['superadmin'],
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    protected: true,
  },
]
