import React from 'react'
import { Area } from '@ant-design/plots'

const AreaChart = ({
  data,
  colors,
  chartHeight,
  xField = 'year',
  yField = 'value',
  theme = 'default',
}) => {
  const config = {
    theme: 'classicDark',
    data: {
      type: 'fetch',
      value: data,
      transform: [{ type: 'filter', callback: (d) => d.symbol === 'GOOG' }],
    },
    height: chartHeight ? chartHeight : 300,

    xField: (d) => new Date(d.date),
    yField: 'price',

    style: {
      fill: `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`,
    },
    axis: {
      y: { labelFormatter: '~s' },
    },
    line: {
      style: {
        stroke: colors[2],
        strokeWidth: 2,
      },
    },
  }

  return <Area {...config} />
}

export default AreaChart
