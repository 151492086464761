import React, { useContext, useState } from 'react'
import { Form, Input, Button, Checkbox, message, Image } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import * as api from 'src/api/authen'
import { setToken } from 'src/utils/accessToken'
import { useNavigate } from 'react-router-dom'
import { appLoad } from 'src/pages/App'
import AppContext from 'src/hooks/AppContext'
import logo from 'src/assets/images/exscapelogo.svg'

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { restore } = useContext(AppContext)

  const onFinish = React.useCallback(async (values) => {
    const { username, password, remember } = values
    try {
      setLoading(true)
      const { data } = await api.login(username, password)
      const { access_token } = data.data
      setToken(remember, access_token)
      const appData = await appLoad()
      restore(appData)
      navigate('/welcome')
    } catch (e) {
      message.error('Login Fail, Please try again!')
    }
  }, [])

  return (
    <div className="login-page">
      <div className="logo-wrap">
        <Image src={logo} />
      </div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your Username!' }]}
        >
          <Input
            size="large"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button btn-primary "
            size="large"
            loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login
