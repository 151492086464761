import { DatePicker, Col, Input, Row, Select, Button } from 'antd'
import React, { useState } from 'react'
import {
  age,
  countries,
  deviceType,
  gameType,
  gender,
  sourceType,
} from 'src/constants'
const { RangePicker } = DatePicker
const AppReportAdvanceSearch = () => {
  const gameTypeList = gameType.map((type) => ({
    value: type.value,
    label: type.name,
  }))

  const _gameTypeHandle = (value: string) => {
    const selectedGameType = gameTypeList.find(
      (country) => country.value === value
    )
  }
  return (
    <div className="master-search-wrap">
      <Row gutter={25}>
        <Col span={4}>
          <Select
            defaultValue={'Select Source'}
            style={{ width: '100%' }}
            onChange={_gameTypeHandle}
            options={sourceType}
            size={'large'}
          />
        </Col>
        <Col span={12}>
          <Input size={'large'} placeholder="Search User" />
        </Col>
        <Col span={8}>
          <RangePicker size={'large'} />
        </Col>
      </Row>

      <Row gutter={20}>
        <Col span={5}>
          <Select
            defaultValue={'Select Gender'}
            style={{ width: '100%' }}
            onChange={_gameTypeHandle}
            options={gender}
            size={'large'}
          />
        </Col>
        <Col span={5}>
          <Select
            defaultValue={'Select Device'}
            style={{ width: '100%' }}
            onChange={_gameTypeHandle}
            options={deviceType}
            size={'large'}
          />
        </Col>
        <Col span={5}>
          <Select
            defaultValue={'Select Age Group'}
            style={{ width: '100%' }}
            onChange={_gameTypeHandle}
            options={age}
            size={'large'}
          />
        </Col>
        <Col span={5}>
          <Select
            defaultValue={'Select Country'}
            style={{ width: '100%' }}
            onChange={_gameTypeHandle}
            options={countries}
            size={'large'}
          />
        </Col>
        <Col span={4}>
          <Button className="btn-primary" size="large" type="primary">
            Search
          </Button>
        </Col>
      </Row>
    </div>
  )
}

AppReportAdvanceSearch.displayName = 'AppReportAdvanceSearch'
export default AppReportAdvanceSearch
