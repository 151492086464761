import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragTableRow from 'src/components/DragTableRow'
import usePage from 'src/hooks/usePage'
import { searchAppUsageReport } from 'src/api/reports'
// import AppUsageReportSearch from './appUsageReportSearch'
import { Table, Card, Row, Col, Statistic, Slider } from 'antd'
// import { searchGameReport } from 'src/api/reports'
import { deviceMarks } from 'src/constants'
import AreaChart from 'src/components/Charts/AreaChart'
import AppReportAdvanceSearch from 'src/pages/Reports/AppUsageReport/appUsageReportAdvanceSearch'

const { Column } = Table

const AppUsageReportPage: React.FC = () => {
  const { pageData, onQuery, appendSearchForm, submitSearchForm, fetchList } =
    usePage({
      getData: searchAppUsageReport,
      searchData: searchAppUsageReport,
      searchType: 'POST',
    })
  const chartData = 'https://assets.antv.antgroup.com/g2/stocks.json'

  const appUsageChartColor = ['#181c84', '#000000', '#5BB2FF'] // Gradient start, end, and line colors

  return (
    <>
      <AppReportAdvanceSearch />
      <Row gutter={20} className="master-data-overview">
        <Col span={14}>
          <Card>
            <div className="chart-wrap">
              <AreaChart
                data={chartData}
                colors={appUsageChartColor}
                chartHeight={190}
              />
            </div>
          </Card>
        </Col>
        <Col span={5}>
          <div className="stats-card-wrap">
            <Card>
              <Statistic title="Total Users" value={1254} suffix={'hr'} />
            </Card>
            <Card className="value-range">
              <Statistic title="Device Played Most" value="" />
              <Slider
                marks={deviceMarks}
                included={false}
                defaultValue={20}
                disabled={true}
              />
            </Card>
          </div>
        </Col>
        <Col span={5}>
          <div className="stats-card-wrap">
            <Card>
              <Statistic title="Total Hours" value={1254} suffix={'hr'} />
            </Card>
            <Card>
              <Statistic title="Average Per Day" value={1254} suffix={'hr'} />
            </Card>
          </div>
        </Col>
      </Row>
      <DndProvider backend={HTML5Backend}>
        <Table
          className="table-sorting"
          rowKey="eventId"
          dataSource={pageData.data}
          loading={pageData.loading}
          pagination={{
            current: parseInt(pageData.query.page) + 1,
            total: pageData.total,
            pageSize: parseInt(pageData.query.pageSize) || 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '25', '50', '100'],
            onChange: (p, ps) => {
              appendSearchForm({ buttonAction: '' })
              onQuery({ page: (p - 1).toString(), pageSize: ps.toString() })
            },
          }}
          // title={() => (
          //   <AppUsageReportSearch
          //     appendSearchForm={appendSearchForm}
          //     submitSearchForm={submitSearchForm}
          //   />
          // )}
          components={{
            body: {
              row: DragTableRow,
            },
          }}
        >
          <Column width={120} dataIndex="userName" title="User Name" />
          <Column dataIndex="age" title="Age" />
          <Column
            dataIndex="gender"
            title="Gender"
            filters={[
              {
                text: 'MALE',
                value: 'MALE',
              },
              {
                text: 'FEMALE',
                value: 'FEMALE',
              },
            ]}
            filterMode="tree"
            onFilter={(value: string, record: any) => record.gender === value}
          />

          <Column dataIndex="country" title="Country" />

          <Column dataIndex="deviceType" title="Device Type" />

          <Column
            dataIndex="eventDuration"
            title="Event Duration"
            render={(text) => <span>{text} sec</span>}
          />
          <Column dataIndex="eventDate" title="Event Date" />
          <Column dataIndex="clientName" title="Client Name" />
          <Column dataIndex="providerCountry" title="Provider" />
        </Table>
      </DndProvider>
    </>
  )
}

AppUsageReportPage.displayName = 'AppUsageReportPage'
export default AppUsageReportPage
