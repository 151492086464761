import React, { useState } from 'react'
import {
  Typography,
  Row,
  Col,
  Card,
  Statistic,
  Select,
  Radio,
  Table,
  Space,
} from 'antd'
const { Title } = Typography
import demoData from 'src/constants/demo.json'
import { quickDate, chartDateType } from 'src/constants'
import AreaChart from 'src/components/Charts/AreaChart'
import { EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
const Dashboard = () => {
  // Mapping the products array to the required format for the options prop
  const { Column } = Table
  const countries = demoData.countries.map((country) => ({
    value: country.value,
    label: country.name,
  }))

  // Set the default value to the first option's value
  const defaultValue = countries[0] ? countries[0].value : undefined
  const [selectedCountryValue, setSelectedCountryValue] = useState(defaultValue)

  const _countryChange = (value: string) => {
    const selectedCountry = countries.find((country) => country.value === value)
    setSelectedCountryValue(selectedCountry ? selectedCountry.label : '')
  }

  const chartData = 'https://assets.antv.antgroup.com/g2/stocks.json'

  const appUsageChartColor = ['#181c84', '#000000', '#5BB2FF'] // Gradient start, end, and line colors
  const gameUsageChartColor = ['#9020c4', '#000000', '#B131EC'] // Gradient start, end, and line colors
  const topGames = [
    {
      key: '1',
      name: 'Ludo',
      playtime: 240,
      url: '',
    },
    {
      key: '2',
      name: 'Ludo',
      playtime: 240,
      url: '',
    },
    {
      key: '1',
      name: 'Ludo',
      playtime: 240,
      url: '',
    },
    {
      key: '2',
      name: 'Ludo',
      playtime: 240,
      url: '',
    },
    {
      key: '1',
      name: 'Ludo',
      playtime: 240,
      url: '',
    },
    {
      key: '2',
      name: 'Ludo',
      playtime: 240,
      url: '',
    },
  ]
  return (
    <div className="dashboard-wrap">
      <div className="top-content">
        <Row gutter={20} align="middle">
          <Col flex={2}>
            <Statistic title="Real Time User" value={112893} />
          </Col>
          <Col flex={2}>
            <Statistic title="Real Time Metaverse" value={112893} />
          </Col>
          <Col flex={2}>
            <Statistic title="Real Time Games" value={112893} />
          </Col>
          <Col flex={6}>
            <Select
              defaultValue={selectedCountryValue}
              style={{ width: 200 }}
              onChange={_countryChange}
              options={countries}
            />
          </Col>
        </Row>
      </div>
      <div className="overview-content">
        <Row gutter={20} align="middle">
          <Col flex={2}>
            <Title level={4}>Overview</Title>
          </Col>
          <Col>
            <Radio.Group
              size={'large'}
              onChange={(e) => {
                console.log()
              }}
            >
              {quickDate.map((dateType) => (
                <Radio.Button value={dateType}>{dateType}</Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Row gutter={20} className="overall-chart">
          <Col span={12}>
            <Card
              title="User Spend Time on App"
              extra={
                <Radio.Group
                  size={'large'}
                  onChange={() => {
                    console.log()
                  }}
                >
                  {chartDateType.map((date) => (
                    <Radio.Button value={date}>{date}</Radio.Button>
                  ))}
                </Radio.Group>
              }
            >
              <div className="stats-card-wrap">
                <Card>
                  <Statistic title="Total Users" value={1254} suffix={'hr'} />
                </Card>
                <Card>
                  <Statistic title="Total Time" value={1254} suffix={'hr'} />
                </Card>
                <Card>
                  <Statistic
                    title="Avg. Time Per Day"
                    value={1254}
                    suffix={'hr'}
                  />
                </Card>
              </div>
              <div className="chart-wrap">
                <AreaChart
                  data={chartData}
                  colors={appUsageChartColor}
                  chartHeight={300}
                />
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="User Spend Time on Games"
              extra={
                <Radio.Group
                  size={'large'}
                  onChange={() => {
                    console.log()
                  }}
                >
                  {chartDateType.map((date) => (
                    <Radio.Button value={date}>{date}</Radio.Button>
                  ))}
                </Radio.Group>
              }
            >
              <div className="stats-card-wrap">
                <Card>
                  <Statistic title="Total Users" value={1254} suffix={'hr'} />
                </Card>
                <Card>
                  <Statistic title="Total Time" value={1254} suffix={'hr'} />
                </Card>
                <Card>
                  <Statistic
                    title="Avg. Time Per Day"
                    value={1254}
                    suffix={'hr'}
                  />
                </Card>
              </div>
              <div className="chart-wrap">
                <AreaChart
                  data={chartData}
                  colors={gameUsageChartColor}
                  chartHeight={300}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row gutter={25} className="features-time-wrap">
          <Col span={4}>
            <Card>
              <Statistic title="Casual Games" value={2} suffix="hr" />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Maze" value={2} suffix="hr" />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Metaverse" value={2} suffix="hr" />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Survivor Zone" value={2} suffix="hr" />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Social" value={2} suffix="hr" />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Marketplace" value={2} suffix="hr" />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="bottom-content">
        <Row gutter={25}>
          <Col span={8}>
            <Card className="list-card">
              <Title level={4}>Top Games</Title>
              <Table dataSource={topGames} pagination={false}>
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Play Time" dataIndex="playtime" key="playtime" />
                <Column
                  title="Action"
                  key="url"
                  render={() => (
                    <Space size="middle">
                      <a className="link-btn">
                        <EyeOutlined />
                      </a>
                    </Space>
                  )}
                />
              </Table>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="list-card">
              <Title level={4}>Top Mazes</Title>
              <Table dataSource={topGames} pagination={false}>
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Play Time" dataIndex="playtime" key="playtime" />
                <Column
                  title="Action"
                  key="url"
                  render={() => (
                    <Space size="middle">
                      <a className="link-btn">
                        <EyeOutlined />
                      </a>
                    </Space>
                  )}
                />
              </Table>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="list-card">
              <Title level={4}>Top Users</Title>
              <Table dataSource={topGames} pagination={false}>
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Play Time" dataIndex="playtime" key="playtime" />
                <Column
                  title="Action"
                  key="url"
                  render={() => (
                    <Space size="middle">
                      <Link
                        to="/reports/usersSearch-reports/user"
                        className="link-btn"
                      >
                        <EyeOutlined />
                      </Link>
                    </Space>
                  )}
                />
              </Table>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Dashboard
